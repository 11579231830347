.login-container {
 
    margin-top: 150px;
    padding: 60px;
    border: 1px solid #ddd;
    border-radius: 150px;
   background: linear-gradient(90deg, rgba(23,5,52,1) 0%, rgba(255,255,255,1) 0%, rgba(255,127,247,0.3360994055825455) 100%, rgba(23,5,52,1) 100%, rgba(25,24,25,1) 100%, rgba(2,0,36,1) 100%);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    text-align: center;
  }

/* 

 position: absolute;
  z-index: 1;

 border-radius: 20%;
    background-color: #f737f1;
    animation: blob-bounce .5s infinite ease;


  @keyframes blob-bounce{
    0%{
      transform: translate(-100%, -100%) translate3d(0,0,0);
    }
    25%{
      transform: translate(-100%, -100%) translate3d(100%,0,0);
    }
    50%{
      transform: translate(-100%, -100%) translate3d(100%,100%,0);
    }
    75%{
      transform: translate(-100%, -100%) translate3d(0,100%,0);
    }
    100%{
      transform: translate(-100%, -100%) translate3d(0,0,0);
    }
  } */

  